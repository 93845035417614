import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {
  addUserReducer,
  getRoleUsersReducer,
  getRolesReducer,
  hotelUpdateReducer,
  monthlyCommissionReducer,
  resetPasswordReducer,
  roleReducer,
  roleUserUpdateReducer,
  shiftCommissionReducer,
  singleUserReducer,
  updateRoleReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from "./Reducers/userReducer";
import {
  allValetTeamGetReducer,
  deleteTeamMemberReducer,
  maidCreateReducer,
  maidGetAllReducer,
  maidGetEarningsReducer,
  maidUpdateReducer,
  payMaidReducer,
  staffGetAllReducer,
  teamMemberAddReducer,
  valetTeamGetReducer,
  verifyPaymentReducer,
} from "./Reducers/maidReducer";
import { teamTipReducer } from "./Reducers/teamTipReducer";
import { partnerRegisterReducer } from "./Reducers/bizDevReducer";
import {
  failedPaymentGetAllReducer,
  getClientPaymentsReducer,
  getPaymentReportsReducer,
  getTeamPayoutsReducer,
  paymentGetAllReducer,
  paymentsByDateReducer,
} from "./Reducers/paymentReducer";
import {
  reportListDownloadtReducer,
  selecetiveMaidReportReducer,
} from "./Reducers/reportsDownloadReducer";
import { navigationReducer } from "./Reducers/tabReducers";
import { chatbotSessionsReducer } from "./Reducers/chatbotSessionReducer";

const reducers = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateProfileReducer,
  hotelUpdate: hotelUpdateReducer,
  userList: userListReducer,
  singleUser: singleUserReducer,
  getRoles: getRolesReducer,
  addRole: roleReducer,
  maidsList: maidGetAllReducer,
  maidCreate: maidCreateReducer,
  maidUpdate: maidUpdateReducer,
  maidEarnings: maidGetEarningsReducer,
  payMaid: payMaidReducer,
  roleUsers: getRoleUsersReducer,
  addRoleUser: addUserReducer,
  teamTipData: teamTipReducer,
  valetTeams: valetTeamGetReducer,
  getAllValetTeams: allValetTeamGetReducer,
  addTeamMember: teamMemberAddReducer,
  deleteTeamMember: deleteTeamMemberReducer,
  verifyPayment: verifyPaymentReducer,
  partnerRegister: partnerRegisterReducer,
  failedPayments: failedPaymentGetAllReducer,
  updateRole: updateRoleReducer,
  updateRoleUser: roleUserUpdateReducer,
  getAllStaff: staffGetAllReducer,
  selectiveMaidData: selecetiveMaidReportReducer,
  reportListData: reportListDownloadtReducer,
  monthlyCommission: monthlyCommissionReducer,
  shiftCommission: shiftCommissionReducer,
  resetPassword: resetPasswordReducer,
  navigation: navigationReducer,
  paymentReports: getPaymentReportsReducer,
  paymentsByDate: paymentsByDateReducer,
  teamPayouts: getTeamPayoutsReducer,
  clientPayments: getClientPaymentsReducer,
  chatbotSessions: chatbotSessionsReducer,
});

const userInfoFromStorage =
  localStorage.getItem("userInfo") !== null &&
  localStorage.getItem("userInfo") !== undefined
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleWares = [thunk];
const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWares))
);
export default store;
