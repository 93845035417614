import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getSingleUser, updateHotel } from "../redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { generatePassword } from "../controllers/passwordGenerator";
import FileInputField from "../components/FileInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faCloudArrowDown,
  faCloudArrowUp,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BACKEND_URL } from "../config";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { setActiveTab } from "../redux/Actions/tabAcions";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import QRCodeGenerator from "./QrGenerator";
import OverlayLoader from "../components/OverlayLoader";
import { message } from "antd";

const EditClientDetails = ({ clientId }) => {
  const [screenLoading, setScreenLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState({});
  const [valetLocationError, setValetLocationError] = useState(false);
  const [valetLocationName, setValetLocationName] = useState("");
  const [locationIndex, setLocationIndex] = useState(null);
  const [showValetLocations, setShowValetLocations] = useState(false);
  const [locationImageUploading, setLocationImageUploading] = useState([]);
  const [qrCodeModal, setQRCodeModal] = useState(false);
  const [newLocationData, setNewLocationData] = useState({
    locationName: "",
    hotelName: "",
    address: "",
  });
  const [serviceFor, setServiceFor] = useState("");
  const [updatedHotel, setUpdatedHotel] = useState({
    id: "",
    companyName: "",
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    location: "",
    accountStatus: false,
    services: {
      maids: false,
      valets: false,
      bellmen: false,
    },
    qrCodes: {
      forMaids: "",
      forValets: "",
      forBellmen: "",
    },
    valetLocations: [
      {
        locationName: "",
        hotelName: "",
        address: "",
        qrCode: "",
      },
    ],
  });
  const { loading, user: client } = useSelector((state) => state.singleUser);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.hotelUpdate);
  const dispatch = useDispatch();

  const handleServiceCheckboxChange = (service) => {
    setUpdatedHotel((prevHotel) => {
      prevHotel.services[service] = !prevHotel.services[service];
      return { ...prevHotel };
    });
  };
  const handleImageUpload = async (e, service) => {
    const file = e.target.files[0];
    setUploadLoading((prevState) => ({
      ...prevState,
      [service]: true, // Set loading true for that service
    }));
    try {
      const formData = new FormData();
      formData.append("qrImage", file);
      const response = await axios.post(
        `${BACKEND_URL}api/upload/qrCodes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const fileUrl = response.data.fileUrl;
      if (response.data.success) {
        setUploadLoading(false);
        setUpdatedHotel((prevHotel) => ({
          ...prevHotel,
          qrCodes: {
            ...prevHotel.qrCodes,
            [service]: fileUrl,
          },
        }));
      }
    } catch (error) {
      setUploadLoading(false);
      console.error("Error uploading the image: ", error);
    }
  };
  const handleValetLocationImageUpload = async (e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    setLocationImageUploading((prevState) => {
      const updatedUploadingStatus = [...prevState];
      updatedUploadingStatus[index] = true;
      return updatedUploadingStatus;
    });
    formData.append("qrImage", file);
    try {
      const response = await axios.post(
        `${BACKEND_URL}api/upload/qrCodes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const fileUrl = response.data.fileUrl;
      if (response.data.success) {
        setUpdatedHotel((prevHotel) => {
          const updatedValetLocations = [...prevHotel.valetLocations];
          updatedValetLocations[index] = {
            ...updatedValetLocations[index],
            qrCode: fileUrl,
          };

          return {
            ...prevHotel,
            valetLocations: updatedValetLocations,
          };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocationImageUploading((prevState) => {
        const updatedUploadingStatus = [...prevState];
        updatedUploadingStatus[index] = false;
        return updatedUploadingStatus;
      });
    }
  };
  const handleOpenChatbot = (ref, id) => {
    const link = `https://dashboard.tipkit.co/bot?ref=${ref}&hotel=${id}`;
    window.open(link, "_blank");
  };
  const handleCopyChatbotLink = (ref, id) => {
    const link = `https://dashboard.tipkit.co/bot?ref=${ref}&hotel=${id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy link:", err);
      });
  };
  const handleCopyLoactionChatbotLink = (id, location) => {
    const link = `https://dashboard.tipkit.co/bot?ref=valet&hotel=${id}&location=${location}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy link:", err);
      });
  };
  const handleAddNewLocation = () => {
    const { locationName, hotelName, address } = newLocationData;
    if (
      locationName.trim() !== "" &&
      hotelName.trim() !== "" &&
      address.trim() !== ""
    ) {
      setValetLocationError(false);
      setUpdatedHotel((prevHotel) => ({
        ...prevHotel,
        valetLocations: [
          ...prevHotel.valetLocations,
          { locationName, hotelName, address },
        ],
      }));
      // Clear the form
      setNewLocationData({
        locationName: "",
        hotelName: "",
        address: "",
      });
      setShowValetLocations(true);
    } else {
      setValetLocationError(true);
    }
  };
  const handleRemoveValetLocation = (indexToRemove) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this valet location?"
    );
    if (isConfirmed) {
      setUpdatedHotel((prevHotel) => {
        const updatedValetLocations = [...prevHotel.valetLocations];
        updatedValetLocations.splice(indexToRemove, 1);

        return {
          ...prevHotel,
          valetLocations: updatedValetLocations,
        };
      });
    }
  };
  const handleEdit = (sendEmail) => {
    // e.preventDefault();
    let updatedPassword = updatedHotel.password;
    let passwordGenerated = false;
    if (!updatedPassword) {
      updatedPassword = generatePassword(updatedHotel.companyName);
      passwordGenerated = true;
    }
    dispatch(
      updateHotel(
        client._id,
        updatedHotel.companyName,
        updatedHotel.name,
        updatedHotel.email,
        passwordGenerated ? updatedPassword : "",
        updatedHotel.phoneNumber,
        updatedHotel.location,
        updatedHotel.services,
        updatedHotel.qrCodes,
        updatedHotel.valetLocations,
        sendEmail
      )
    );
  };
  const handleBackButton = () => {
    dispatch(setActiveTab("#dashboard"));
  };
  const handleQRCodeModal = () => setQRCodeModal(false);

  useEffect(() => {
    dispatch(getSingleUser(clientId));
  }, [clientId]);

  useEffect(() => {
    let hideLoading;
    if (updateLoading) {
      hideLoading = message.loading("Updating", 0);
    }
    if (updateSuccess) {
      if (hideLoading) hideLoading();
      message.success("Updated!");
    }
    if (updateError) {
      if (hideLoading) hideLoading();
      message.error(updateError || "Failed to update");
    }
    return () => {
      if (hideLoading) hideLoading();
    };
  }, [updateLoading, updateSuccess, updateError]);

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 1000);
    if (client != null) {
      const mapValetLocations = (locations) =>
        locations.map((location) =>
          typeof location === "string"
            ? { locationName: location, hotelName: "", address: "", qrCode: "" }
            : {
                locationName: location.locationName || "",
                hotelName: location.hotelName || "",
                address: location.address || "",
                qrCode: location.qrCode || "",
              }
        );
      setUpdatedHotel((prevHotel) => ({
        ...prevHotel,
        id: client._id,
        companyName: client.companyName,
        name: client.name,
        email: client.email,
        password: client.password,
        phoneNumber: client.phoneNumber,
        location: client.location,
        services: {
          maids: client.services?.maids || false,
          valets: client.services?.valets || false,
          bellmen: client.services?.bellmen || false,
        },
        qrCodes: {
          forMaids: client.qrCodes?.forMaids,
          forValets: client.qrCodes?.forValets,
          forBellmen: client.qrCodes?.forBellmen,
        },
        accountStatus: client.accountStatus || false,
        valetLocations: Array.isArray(client.valetLocations)
          ? mapValetLocations(client.valetLocations)
          : [],
      }));
    }
  }, [client]);

  return (
    <>
      <QRCodeGenerator
        show={qrCodeModal}
        onHide={handleQRCodeModal}
        service={serviceFor}
        hotelId={updatedHotel.id}
        upload={handleImageUpload}
        valetLocationName={valetLocationName}
        locationQrUpload={handleValetLocationImageUpload}
        index={locationIndex}
      />
      {loading || screenLoading ? (
        <OverlayLoader />
      ) : (
        <Row className="p-4">
          <Col className="p-0">
            <Row className="mb-3 text-primary">
              <Col
                className="hover-effect"
                xs="auto"
                onClick={handleBackButton}
              >
                <FontAwesomeIcon icon={faChevronLeft} className=" me-2" />
                <span className="fw-semibold">Dashboard</span>
              </Col>
            </Row>
            <Row>
              <Col xs={7} className="p-3">
                <h4 className="text-primary text-center mb-5 fw-semibold">
                  Edit Details
                </h4>
                <Form>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-semibold">
                          Hotel Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={updatedHotel.companyName}
                          onChange={(e) =>
                            setUpdatedHotel({
                              ...updatedHotel,
                              companyName: e.target.value,
                            })
                          }
                          placeholder="Enter Company Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-semibold">
                          Contact Person Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={updatedHotel.name}
                          onChange={(e) =>
                            setUpdatedHotel({
                              ...updatedHotel,
                              name: e.target.value,
                            })
                          }
                          placeholder="Enter Contact Person Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-semibold">
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          value={updatedHotel.email}
                          onChange={(e) =>
                            setUpdatedHotel({
                              ...updatedHotel,
                              email: e.target.value,
                            })
                          }
                          placeholder="Enter Email"
                        />
                        <Form.Text className="text-muted fst-italic">
                          This is the login email*
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fw-semibold">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={updatedHotel.phoneNumber}
                      onChange={(e) =>
                        setUpdatedHotel({
                          ...updatedHotel,
                          phoneNumber: e.target.value,
                        })
                      }
                      placeholder="Enter Phone Number"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fw-semibold">
                      Address Line
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={updatedHotel.location}
                      onChange={(e) =>
                        setUpdatedHotel({
                          ...updatedHotel,
                          location: e.target.value,
                        })
                      }
                      placeholder="Enter Location"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fw-semibold">
                      Services Selected :
                    </Form.Label>
                    <Row className="justify-content-around mt-3 px-3">
                      <Col sm={4} className="border border-1 rounded-4 p-3">
                        <Form.Check
                          type="checkbox"
                          label="Attendants Dashboard"
                          checked={updatedHotel.services?.maids || false}
                          onChange={() => handleServiceCheckboxChange("maids")}
                        />
                      </Col>
                      <Col sm={4} className="border border-1 rounded-4 p-3">
                        <Form.Check
                          type="checkbox"
                          label="Bellmen Dashboard"
                          checked={updatedHotel.services?.bellmen || false}
                          onChange={() =>
                            handleServiceCheckboxChange("bellmen")
                          }
                        />
                      </Col>
                      <Col sm={3} className="border border-1 rounded-4 py-3">
                        <Form.Check
                          type="checkbox"
                          label="Valet Dashboard"
                          checked={updatedHotel.services?.valets || false}
                          onChange={() => handleServiceCheckboxChange("valets")}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
              {/* ------------------------------------------------------ QR CODES ---------------------------------------------- */}
              <Col xs={5} className="border-start p-3">
                <h4 className="text-primary text-center mb-4 fw-semibold">
                  QR Codes
                </h4>
                {updatedHotel?.services?.maids && (
                  <Row className="justify-content-between align-items-center mb-5">
                    <Col>
                      <h4 className="text-primary">Attendants</h4>
                      <Row className="ps-2">
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() =>
                              handleOpenChatbot("maid", updatedHotel.id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faArrowUpRightFromSquare}
                              className="me-2"
                            />
                            Open
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() =>
                              handleCopyChatbotLink("maid", updatedHotel.id)
                            }
                          >
                            <FontAwesomeIcon icon={faCopy} className="me-2" />
                            Copy
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() => {
                              setQRCodeModal(true);
                              setServiceFor("forMaids");
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Create
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <FileInputField
                            uploaded={
                              updatedHotel.qrCodes?.forMaids == ""
                                ? false
                                : true
                            }
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "forMaids")}
                          />
                        </Col>
                        <Col xs="auto">
                          {uploadLoading["forMaids"] ? <Loader /> : <></>}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <img
                        style={{ width: "120px", height: "120px" }}
                        src={
                          updatedHotel.qrCodes?.forMaids !== ""
                            ? `${updatedHotel.qrCodes?.forMaids}`
                            : "https://placehold.co/120x120?text=No+Image"
                        }
                        alt="QR code"
                      />
                    </Col>
                  </Row>
                )}
                {updatedHotel?.services?.valets && (
                  <Row className="justify-content-between align-items-center mb-5">
                    <Col>
                      <h4 className="text-primary">Valets</h4>
                      <Row className="ps-2">
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() =>
                              handleOpenChatbot("valet", updatedHotel.id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faArrowUpRightFromSquare}
                              className="me-2"
                            />
                            Open
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() =>
                              handleCopyChatbotLink("valet", updatedHotel.id)
                            }
                          >
                            <FontAwesomeIcon icon={faCopy} className="me-2" />
                            Copy
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() => {
                              setQRCodeModal(true);
                              setServiceFor("forValets");
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Create
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <FileInputField
                            uploaded={
                              updatedHotel.qrCodes?.forValets == ""
                                ? false
                                : true
                            }
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "forValets")}
                          />
                        </Col>
                        <Col xs="auto">
                          {uploadLoading["forValets"] ? <Loader /> : <></>}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <img
                        style={{ width: "120px", height: "120px" }}
                        src={
                          updatedHotel.qrCodes?.forValets !== ""
                            ? `${updatedHotel.qrCodes?.forValets}`
                            : "https://placehold.co/120x120?text=No+Image"
                        }
                        alt="QR code"
                      />
                    </Col>
                  </Row>
                )}
                {updatedHotel?.services?.bellmen && (
                  <Row className="justify-content-between align-items-center mb-5">
                    <Col>
                      <h4 className="text-primary">Bell Men</h4>
                      <Row className="ps-2">
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() =>
                              handleOpenChatbot("bellman", updatedHotel.id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faArrowUpRightFromSquare}
                              className="me-2"
                            />
                            Open
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() =>
                              handleCopyChatbotLink("bellman", updatedHotel.id)
                            }
                          >
                            <FontAwesomeIcon icon={faCopy} className="me-2" />
                            Copy
                          </Button>
                        </Col>

                        <Col xs="auto" className="p-0">
                          <Button
                            variant="outline-primary"
                            className="border-0 p-1 px-2"
                            onClick={() => {
                              setQRCodeModal(true);
                              setServiceFor("forBellmen");
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Create
                          </Button>
                        </Col>
                        <Col xs="auto" className="p-0">
                          <FileInputField
                            uploaded={
                              updatedHotel.qrCodes?.forBellmen == ""
                                ? false
                                : true
                            }
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "forBellmen")}
                          />
                        </Col>
                        <Col xs="auto">
                          {uploadLoading["forBellmen"] ? <Loader /> : <></>}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <img
                        style={{ width: "120px", height: "120px" }}
                        src={
                          updatedHotel.qrCodes?.forBellmen !== ""
                            ? `${updatedHotel.qrCodes?.forBellmen}`
                            : "https://placehold.co/120x120?text=No+Image"
                        }
                        alt="QR code"
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {/* -------------------------------------------------- VALET LOCATIONS ---------------------------------------------- */}
            <Row className="mt-5">
              <Col>
                {updatedHotel.services?.valets && (
                  <Form.Group className="rounded-4 p-3 bg-light mt-3">
                    <Row>
                      <Col xs="auto">
                        <Form.Label>
                          Valet Locations
                          <span className="fst-italic fs-6 text-muted">
                            (Optional)
                          </span>
                        </Form.Label>
                      </Col>
                      <Col xs="auto" className="ms-auto">
                        <Button
                          className="rounded-4"
                          variant="outline-secondary"
                          onClick={() =>
                            setShowValetLocations(!showValetLocations)
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              showValetLocations ? faChevronUp : faChevronDown
                            }
                          />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          value={newLocationData.locationName}
                          onChange={(e) =>
                            setNewLocationData({
                              ...newLocationData,
                              locationName: e.target.value,
                            })
                          }
                          placeholder="Enter Valet Location"
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          value={newLocationData.hotelName}
                          onChange={(e) =>
                            setNewLocationData({
                              ...newLocationData,
                              hotelName: e.target.value,
                            })
                          }
                          placeholder="Enter Hotel Name"
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          value={newLocationData.address}
                          onChange={(e) =>
                            setNewLocationData({
                              ...newLocationData,
                              address: e.target.value,
                            })
                          }
                          placeholder="Enter Hotel Address"
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={handleAddNewLocation}
                          className="rounded-3"
                        >
                          <FontAwesomeIcon icon={faPlus} className="me-1" /> Add
                        </Button>
                      </Col>
                    </Row>
                    {valetLocationError && (
                      <span className="text-danger fst-italic fs-7 mx-2">
                        All feilds are required*
                      </span>
                    )}
                    {/* ----------------------------------------- VALET LOCATIONS --------------------------------- */}
                    <Row className="mt-2 p-3 w-100">
                      <Col
                        className={`transition ${
                          showValetLocations ? "visible" : "hidden"
                        }`}
                      >
                        {updatedHotel.valetLocations.length > 0 && (
                          <>
                            <Row>
                              <Col xs={2}>Location</Col>
                              <Col xs={2}>Hotel Name</Col>
                              <Col xs={3}>Address</Col>
                              <Col xs={1} className="text-center">
                                QR code
                              </Col>
                            </Row>
                          </>
                        )}
                        <div
                          style={{
                            maxHeight: "400px",
                            overflowX: "hidden",
                            overflowY: "auto",
                          }}
                        >
                          {updatedHotel.valetLocations.length > 0
                            ? updatedHotel.valetLocations.map(
                                (location, index) => (
                                  <div key={index}>
                                    <Row className="border border-1 rounded-4 p-1 my-2 bg-white align-items-center">
                                      <Col xs={2} className="fw-semibold">
                                        {location.locationName}
                                      </Col>
                                      <Col xs={2}>{location.hotelName}</Col>
                                      <Col xs={3}>{location.address}</Col>
                                      <Col xs="auto">
                                        <img
                                          style={{
                                            width: "120px",
                                            height: "120px",
                                          }}
                                          src={
                                            location.qrCode
                                              ? `${location.qrCode}`
                                              : "https://placehold.co/120x120?text=No+Image"
                                          }
                                          alt="QR code"
                                        />
                                      </Col>
                                      {/* <Col xs={1} className="text-center">
                                        {location.qrCode ? (
                                          <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            className="text-success"
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faCircleXmark}
                                            className="text-danger"
                                          />
                                        )}
                                      </Col> */}

                                      <Col xs="auto">
                                        <Button
                                          variant="outline-primary"
                                          className="border-0 p-1 px-2"
                                          onClick={() => {
                                            setQRCodeModal(true);
                                            setValetLocationName(
                                              location.locationName
                                            );
                                            setServiceFor("forValets");
                                            setLocationIndex(index);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            className="me-2"
                                          />
                                          Create
                                        </Button>
                                      </Col>
                                      <Col xs="auto" className="p-0">
                                        <Row className="justify-content-end">
                                          <Col xs="auto">
                                            <FileInputField
                                              uploaded={
                                                location.qrCode ? true : false
                                              }
                                              accept="image/*"
                                              onChange={(e) =>
                                                handleValetLocationImageUpload(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col xs="auto">
                                            {locationImageUploading[index] ? (
                                              <Loader />
                                            ) : null}
                                          </Col>
                                          <Col
                                            xs="auto"
                                            className="text-center p-0"
                                          >
                                            <Button
                                              variant="outline-primary"
                                              className="border-0"
                                              onClick={() =>
                                                handleCopyLoactionChatbotLink(
                                                  updatedHotel.id,
                                                  location.locationName
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon icon={faCopy} />
                                            </Button>
                                          </Col>
                                          <Col xs="auto" className="p-0">
                                            <Button
                                              variant="outline-danger"
                                              className="border-0"
                                              onClick={() =>
                                                handleRemoveValetLocation(index)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                // className="text-danger border-0 hover-effect"
                                                icon={faTrash}
                                              />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              )
                            : null}
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row className="mt-5 mb-3">
              <Col sm="auto" className="mx-auto">
                {updatedHotel.password != "" && (
                  <Button
                    disabled={updateLoading}
                    className="px-2 ms-3 rounded-3"
                    variant="success"
                    type="submit"
                    onClick={() => handleEdit(false)}
                  >
                    {/* {updateLoading ? (
                      "Saving..."
                    ) : updateSuccess ? (
                      <span>
                        <FontAwesomeIcon className="me-2" icon={faCheck} />
                        Saved
                      </span>
                    ) : ( */}
                    Save Changes
                    {/* )} */}
                  </Button>
                )}
                <Button
                  disabled={updateLoading}
                  className="px-2 ms-3 rounded-3"
                  variant="primary"
                  type="submit"
                  onClick={() => handleEdit(true)}
                >
                  Save and Email
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EditClientDetails;
