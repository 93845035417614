import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
  faXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Register from "./Register";
import {
  deleteUser,
  getUserDetails,
  listUsers,
} from "../redux/Actions/userAction";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  HOTEL_UPDATE_RESET,
  USER_REGISTER_RESET,
} from "../redux/Constants/userconstants";
import ClientsCard from "../components/ClientsCard";
import MaidsCard from "../components/MaidsCard";
import { getAllStaff } from "../redux/Actions/maidAction";
import PaymentsCard from "../components/PaymentsCard";
import StaffDetail from "./StaffDetail";
import ClientDetail from "./ClientDetail";
import OverlayLoader from "../components/OverlayLoader";
import { impersonateUser } from "../redux/Actions/userAction";

const AdminDashboard = ({ onTabChange, setCollapsed }) => {
  const navigate = useNavigate();
  let isProcessing = false;
  const [createdPassword, setCreatedPassword] = useState("");
  const [userListUpdate, setUserListUpdate] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToDeleteName, setUserToDeleteName] = useState("");
  const [hotelToEdit, setHotelToEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [show, setShow] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [clientDetailsModal, setClientDetailsModal] = useState(false);
  const { loading, error, users, success } = useSelector(
    (state) => state.userList
  );
  const { staff } = useSelector((state) => state.getAllStaff);
  const {
    loading: userLoading,
    error: userError,
    user,
  } = useSelector((state) => state.userDetails);
  const userId = user && user._id;

  const hotels =
    users.length > 0 && user.isSuperAdmin
      ? users.filter((user) => !user.isSuperAdmin && !user.isPartner)
      : users.length > 0 && user.isPartner
      ? users.filter(
          (user) =>
            !user.isSuperAdmin && !user.isPartner && user.partnerId === userId
        )
      : [];

  const dispatch = useDispatch();
  // --------------------------------------------------- MODAL HANDLERS ------------------------------------------------------
  const handleDeleteModal = () => setDeleteModal(false);
  const handleClose = () => setShow(false);
  const handleStaffModal = () => setStaffModal(false);
  const handleClientDetailsModal = () => setClientDetailsModal(false);
  // --------------------------------------------------- EVENT HANDLERS ----------------------------------------------------
  const handleAddNew = () => {
    dispatch({ type: USER_REGISTER_RESET });
    setShow(true);
  };
  const handleEditClient = (id) => {
    // onTabChange("#editClient", id);
    if (isProcessing) return;
    isProcessing = true;
    try {
      onTabChange("#editClient", id);
      setCollapsed(true);
    } finally {
      isProcessing = false;
    }
  };
  const handleView = (client) => {
    if (isProcessing) return;
    isProcessing = true;
    setScreenLoading(true);
    setTimeout(() => {
      dispatch(impersonateUser(client._id, client.email, navigate));
      isProcessing = false;
    }, 1000);
  };
  const handleDelete = () => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete));
      setUserToDelete(null);
      setUserToDeleteName("");
      setDeleteModal(false);
    }
  };
  const getDevPartner = (partnerId) => {
    if (user) {
      const partnerUser = users.find((user) => user._id === partnerId);
      if (partnerUser) {
        return partnerUser.name;
      }
    }
    return "Not defined";
  };
  const handleFilter = () => {
    if (selectedFilter === "all") {
      setFilteredHotels(hotels);
    } else if (selectedFilter === "b2b") {
      const b2bHotels = hotels.filter((hotel) => hotel.model.b2b === true);
      setFilteredHotels(b2bHotels);
    } else if (selectedFilter === "b2c") {
      const b2cHotels = hotels.filter((hotel) => hotel.model.b2c === true);
      setFilteredHotels(b2cHotels);
    } else if (selectedFilter === "partner") {
      const partnerHotels = hotels.filter(
        (hotel) => hotel.partnerId != "65b949bd84febb1899b71e0e"
      );
      setFilteredHotels(partnerHotels);
    }
  };
  useEffect(() => {
    dispatch(listUsers());
    dispatch(getAllStaff());
    dispatch(getUserDetails());
  }, [dispatch, userListUpdate, success]);

  useEffect(() => {
    handleFilter();
  }, [selectedFilter]);

  const styles = {
    shadow: {
      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    shadowCard: {
      boxShadow:
        " rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },
  };
  return (
    <>
      {/* --------------------------------------------------- CLIENT DETAIL MODAL ------------------------------------------ */}
      <Modal
        show={clientDetailsModal}
        onHide={handleClientDetailsModal}
        backdrop="static"
        fullscreen
        centered
      >
        <Modal.Header className="bg-primary">
          <Row className="w-100 align-items-center">
            <Col className="text-center text-light fs-4"></Col>
            <Col md="auto" className="ms-auto p-0 d-inline">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClientDetailsModal}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <ClientDetail staff={staff && staff} clients={hotels} />
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------- STAFF DETAIL MODAL ------------------------------------------ */}
      <Modal
        show={staffModal}
        onHide={handleStaffModal}
        backdrop="static"
        fullscreen
        centered
      >
        <Modal.Header className="bg-primary">
          <Row className="w-100 align-items-center">
            <Col className="text-center text-light fs-4"></Col>
            <Col md="auto" className="ms-auto p-0 d-inline">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleStaffModal}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <StaffDetail hotels={hotels} staff={staff} />
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------- DELETE MODAL ------------------------------------------ */}
      <Modal
        show={deleteModal}
        onHide={handleDeleteModal}
        backdrop="static"
        centered
      >
        <Modal.Body className="p-4 text-center">
          <b>
            Are you sure you want to remove
            <span className="text-danger mx-1">
              {userToDeleteName != "" && userToDeleteName}
            </span>
            ?
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------------------------------------------------- ADD NEW MODAL ----------------------------------------- */}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send An Invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Register
            setUserListUpdate={setUserListUpdate}
            setCreatedPassword={setCreatedPassword}
            userId={userId}
          />
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------  CARDS ------------------------------------------------- */}
      {userId == "65b949bd84febb1899b71e0e" && (
        <Row className="justify-content-evenly m-0 mt-5">
          <Col sm={3}>
            <Card
              className="rounded-4 border-0 hover-effect"
              style={styles.shadow}
              onClick={() => {
                staff.length <= 0
                  ? alert("Please wait for the data")
                  : setClientDetailsModal(true);
              }}
            >
              <Card.Body className="p-4">
                <ClientsCard clients={hotels} />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card
              className="rounded-4 border-0 hover-effect"
              style={styles.shadow}
              onClick={() => {
                staff.length <= 0
                  ? alert("Please wait for the data")
                  : setStaffModal(true);
              }}
            >
              <Card.Body className="p-4">
                <MaidsCard staff={staff} />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card
              className="rounded-4 border-0 hover-effect"
              style={styles.shadow}
            >
              <Card.Body className="p-4">
                <PaymentsCard />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {/* ----------------------------------------------- ADD NEW BUTTON --------------------------------- */}
      <Row className="px-5 align-items-center m-0 mt-5">
        <Col sm={2} className="ms-auto">
          <Form.Select
            className="rounded-3 "
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
          >
            <option value="all">All</option>
            <option value="b2b">B2B</option>
            <option value="b2c">B2C</option>
            <option value="partner">Dev Partner Client</option>
          </Form.Select>
        </Col>
        <Col sm={3} className="">
          <InputGroup className="ms-auto border border-1 rounded-3 ">
            <Button className="bg-transparent border-0" id="button-addon1">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-primary"
              />
            </Button>
            <Form.Control
              className="border-0"
              aria-label="search"
              aria-describedby="basic-addon1"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col sm="auto" className="me-3">
          <Button className="px-3 rounded-3" onClick={handleAddNew}>
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add new
          </Button>
        </Col>
      </Row>
      {/* ---------------------------------------------- HOTELS TABLE ------------------------------------------ */}
      <Row className="p-4 m-0 my-3 ">
        <Col className="p-3 py-4  rounded-4" style={styles.shadow}>
          <Row>
            <Col sm="auto" className="me-2 fw-bold">
              #
            </Col>
            <Col sm={1} className="fw-bold">
              Date
            </Col>
            <Col sm={3} className="fw-bold">
              Client Name
            </Col>
            <Col sm={2} className="fw-bold">
              Contact Person
            </Col>
            <Col sm={3} className="fw-bold">
              Location
            </Col>
            <Col xs={2} className="text-center fw-bold">
              Actions
            </Col>
          </Row>
          <hr className="mt-3 mb-1" />
          {loading ? (
            <Row className="justify-content-center">
              <Col sm="auto">
                <Loader />
              </Col>
            </Row>
          ) : error ? (
            <Row className="justify-content-center">
              <Col sm="auto">
                <Message>{error}</Message>
              </Col>
            </Row>
          ) : (
            (filteredHotels?.length > 0 ? filteredHotels : hotels)
              .filter((hotel) =>
                hotel.companyName
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              .slice()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((hotel, index) => (
                <div key={hotel._id}>
                  <Row className="align-items-center">
                    <Col sm="auto" className="me-2 fw-bold">
                      {index + 1}.
                    </Col>
                    <Col sm={1}>
                      {new Date(hotel.createdAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </Col>
                    <Col sm={3} className="fw-semibold align-items-center">
                      {hotel.partnerId != "65b949bd84febb1899b71e0e" &&
                        user.isSuperAdmin && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {getDevPartner(hotel.partnerId)}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={faHandshake}
                              className="me-2 fs-6 text-info hover-effect"
                            />
                          </OverlayTrigger>
                        )}
                      {hotel.model.b2b === true && hotel.isAdminX !== true && (
                        <span
                          className={`border border-1 py-0 px-1 rounded-1 me-1 fw-normal ${
                            hotel.chargesStatus
                              ? "text-success border-success"
                              : "text-danger border-danger"
                          }`}
                          style={{ fontSize: "10px" }}
                        >
                          B2B
                        </span>
                      )}
                      {hotel.companyName}
                    </Col>
                    <Col sm={2}>{hotel.name}</Col>
                    <Col sm={3} className="text-success">
                      {hotel.location}
                    </Col>
                    <Col xs={2} className="ms-auto">
                      <Button
                        variant="outline-primary"
                        className="m-2 py-1 px-2"
                        onClick={() => {
                          handleEditClient(hotel._id);
                          setCollapsed(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="m-2 py-1 px-2"
                        onClick={() => {
                          handleView(hotel);
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="m-2 py-1 px-2"
                        onClick={() => {
                          setDeleteModal(true);
                          setUserToDelete(hotel._id);
                          setUserToDeleteName(hotel.companyName);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                  </Row>
                  <hr className="my-1" />
                </div>
              ))
          )}
        </Col>
      </Row>
    </>
  );
};

export default AdminDashboard;
