import { Empty } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";

const AdminXReports = () => {
  return (
    <Row className="vh-100 align-items-center">
      <Col>
        <Empty description="Under Development" />
      </Col>
    </Row>
  );
};

export default AdminXReports;
