import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { QRCode } from "react-qrcode-logo";
import { faChevronDown, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../components/Loader";

function generateRandomString() {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

export const QRCodeGenerator = ({
  show,
  onHide,
  service,
  hotelId,
  upload,
  valetLocationName,
  locationQrUpload,
  index,
}) => {
  const [qrData, setQRData] = useState({
    title: "",
    url: "",
    fgColor: "#000000",
    bgColor: "#ffffff",
    shape: "square",
    image: null,
  });
  const [eyeSettings, setEyeSettings] = useState(false);
  const qrRef = useRef();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQRData((prev) => ({ ...prev, [name]: value }));
  };

  const handleColorChange = (e, key) => {
    setQRData((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleShapeChange = (e) => {
    setQRData((prev) => ({ ...prev, shape: e.target.value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQRData((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const fileInputRef = React.createRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleChange = ({ target }) => {
    setState((prevState) => ({ ...prevState, [target.name]: target.value }));
  };

  const saveQRCode = async () => {
    try {
      setLoading(true);

      const canvas = qrRef.current.querySelector("canvas");
      if (!canvas) {
        console.error("QR code canvas not found.");
        return;
      }

      const blob = await new Promise((resolve) => canvas.toBlob(resolve));

      if (blob) {
        const file = new File(
          [blob],
          `${(qrData.title || "qr_code") + generateRandomString()}.png`,
          {
            type: "image/png",
          }
        );
        if (valetLocationName === "") {
          // forMaids, forValets,forBellmen
          await upload({ target: { files: [file] } }, service);
        } else if (valetLocationName != "") {
          await locationQrUpload({ target: { files: [file] } }, index);
        }

        onHide();
      } else {
        console.error("Failed to generate QR code blob.");
      }
    } catch (error) {
      console.error("Error saving QR code: ", error);
    } finally {
      setLoading(false);
    }
  };

  const buildEyeRadiusInput = (id) => {
    return (
      <Form.Group controlId={id}>
        <Form.Range
          name={id}
          min={0}
          max={50}
          value={state[id]}
          onChange={(e) => handleChange(e)}
          style={{ width: "130px", height: "8px" }}
        />
      </Form.Group>
    );
  };

  useEffect(() => {
    setQRData((prev) => ({
      ...prev,
      url: `https://dashboard.tipkit.co/bot?ref=${
        service === "forValets"
          ? "valet"
          : service === "forBellmen"
          ? "bellman"
          : service === "forMaids"
          ? "maid"
          : ""
      }&hotel=${hotelId}${
        valetLocationName != "" ? `&location=${valetLocationName}` : ""
      }`,
    }));
  }, [service, hotelId]);

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Generate QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={qrData.title}
                onChange={handleInputChange}
                placeholder="Enter QR Code title"
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>URL</Form.Label>
              <Form.Control
                disabled
                type="url"
                name="url"
                value={qrData.url}
                onChange={handleInputChange}
                placeholder="Enter URL"
              />
            </Form.Group>

            <Row className="mt-3 justify-content-center align-items-center">
              <Col xs="auto" className="p-0">
                <Form.Control
                  type="color"
                  value={qrData.fgColor}
                  onChange={(e) => handleColorChange(e, "fgColor")}
                  title="Foreground Color"
                  style={{ width: "50px", height: "40px" }}
                />
              </Col>
              <Col xs="auto" className="">
                <Form.Control
                  type="color"
                  value={qrData.bgColor}
                  onChange={(e) => handleColorChange(e, "bgColor")}
                  title="Background Color"
                  style={{ width: "50px", height: "40px" }}
                />
              </Col>
              <Col xs="auto">
                <Form.Select
                  value={qrData.shape}
                  onChange={handleShapeChange}
                  style={{ width: "120px", height: "40px" }}
                >
                  <option value="squares">Squares</option>
                  <option value="dots">Dots</option>
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Group>
                  <Button variant="outline-primary" onClick={triggerFileInput}>
                    Add Image
                  </Button>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Button
                  variant="outline-primary"
                  className=" "
                  onClick={() => {
                    setEyeSettings(!eyeSettings);
                  }}
                >
                  More <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              </Col>
            </Row>

            {/* Upload Button */}
            {/* <Form.Group className="mt-3">
              <Button
                variant="primary"
                onClick={triggerFileInput}
                style={{ width: "50%" }}
              >
                CHOOSE IMAGE
              </Button>
              <Form.Control
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </Form.Group> */}
            {eyeSettings && (
              <>
                <Row className="mt-3 align-items-center">
                  <Col xs="auto" className="ms-2">
                    <Form.Label> Top left (outer)</Form.Label>

                    {buildEyeRadiusInput("eyeradius_0_outer_0")}
                    {buildEyeRadiusInput("eyeradius_0_outer_1")}
                    {buildEyeRadiusInput("eyeradius_0_outer_2")}
                    {buildEyeRadiusInput("eyeradius_0_outer_3")}

                    <Form.Label>inner </Form.Label>
                    {buildEyeRadiusInput("eyeradius_0_inner_0")}
                    {buildEyeRadiusInput("eyeradius_0_inner_1")}
                    {buildEyeRadiusInput("eyeradius_0_inner_2")}
                    {buildEyeRadiusInput("eyeradius_0_inner_3")}
                  </Col>
                  <Col xs="auto" className="ms-2">
                    <Form.Label>Top right (outer)</Form.Label>
                    {buildEyeRadiusInput("eyeradius_1_outer_0")}
                    {buildEyeRadiusInput("eyeradius_1_outer_1")}
                    {buildEyeRadiusInput("eyeradius_1_outer_2")}
                    {buildEyeRadiusInput("eyeradius_1_outer_3")}

                    <Form.Label>inner </Form.Label>
                    {buildEyeRadiusInput("eyeradius_1_inner_0")}
                    {buildEyeRadiusInput("eyeradius_1_inner_1")}
                    {buildEyeRadiusInput("eyeradius_1_inner_2")}
                    {buildEyeRadiusInput("eyeradius_1_inner_3")}
                  </Col>
                  <Col xs="auto" className="ms-2">
                    <Form.Label>Bottom left (outer)</Form.Label>
                    {buildEyeRadiusInput("eyeradius_2_outer_0")}
                    {buildEyeRadiusInput("eyeradius_2_outer_1")}
                    {buildEyeRadiusInput("eyeradius_2_outer_2")}
                    {buildEyeRadiusInput("eyeradius_2_outer_3")}

                    <Form.Label>inner </Form.Label>
                    {buildEyeRadiusInput("eyeradius_2_inner_0")}
                    {buildEyeRadiusInput("eyeradius_2_inner_1")}
                    {buildEyeRadiusInput("eyeradius_2_inner_2")}
                    {buildEyeRadiusInput("eyeradius_2_inner_3")}
                  </Col>
                </Row>
                <Row className="mt-3 align-items-center">
                  <Col xs="auto" className="ms-2">
                    <Form.Label>outer </Form.Label>
                    <Form.Control
                      name="eyecolor_0_outer"
                      type="color"
                      defaultValue={state.fgColor ?? "#000000"}
                      onChange={(e) => handleChange(e)}
                      style={{ width: "50px", height: "40px" }}
                    />
                    <Form.Label>inner </Form.Label>
                    <Form.Control
                      name="eyecolor_0_inner"
                      type="color"
                      defaultValue={state.fgColor ?? "#000000"}
                      onChange={(e) => handleChange(e)}
                      style={{ width: "50px", height: "40px" }}
                    />
                  </Col>

                  <Col xs="auto" className="ms-2">
                    <Form.Label>outer </Form.Label>
                    <Form.Control
                      name="eyecolor_1_outer"
                      type="color"
                      defaultValue={state.fgColor ?? "#000000"}
                      onChange={(e) => handleChange(e)}
                      style={{ width: "50px", height: "40px" }}
                    />
                    <Form.Label>inner </Form.Label>
                    <Form.Control
                      name="eyecolor_1_inner"
                      type="color"
                      defaultValue={state.fgColor ?? "#000000"}
                      onChange={(e) => handleChange(e)}
                      style={{ width: "50px", height: "40px" }}
                    />
                  </Col>

                  <Col xs="auto" className="ms-2">
                    <Form.Label>outer </Form.Label>
                    <Form.Control
                      name="eyecolor_2_outer"
                      type="color"
                      defaultValue={state.fgColor ?? "#000000"}
                      onChange={(e) => handleChange(e)}
                      style={{ width: "50px", height: "40px" }}
                    />
                    <Form.Label>inner </Form.Label>
                    <Form.Control
                      name="eyecolor_2_inner"
                      type="color"
                      defaultValue={state.fgColor ?? "#000000"}
                      onChange={(e) => handleChange(e)}
                      style={{ width: "50px", height: "40px" }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>

          <Col md={6} className="text-center">
            <div
              ref={qrRef}
              style={{
                display: "inline-block",
                padding: "60px",
                border: "2px solid #ddd",
                borderRadius: "45px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
              }}
            >
              <QRCode
                logoOnLoad={() => console.log("logo loaded")}
                logoImage={qrData.image}
                logoWidth={30}
                logoHeight={30}
                removeQrCodeBehindLogo={true}
                logoPadding={15}
                logoPaddingStyle="square"
                size={150}
                value={qrData.url || "https://example.com"}
                fgColor={qrData.fgColor}
                bgColor={qrData.bgColor}
                qrStyle={qrData.shape}
                ecLevel="H"
                includeMargin
                eyeRadius={[
                  {
                    outer: [
                      state.eyeradius_0_outer_0,
                      state.eyeradius_0_outer_1,
                      state.eyeradius_0_outer_2,
                      state.eyeradius_0_outer_3,
                    ],
                    inner: [
                      state.eyeradius_0_inner_0,
                      state.eyeradius_0_inner_1,
                      state.eyeradius_0_inner_2,
                      state.eyeradius_0_inner_3,
                    ],
                  },
                  {
                    outer: [
                      state.eyeradius_1_outer_0,
                      state.eyeradius_1_outer_1,
                      state.eyeradius_1_outer_2,
                      state.eyeradius_1_outer_3,
                    ],
                    inner: [
                      state.eyeradius_1_inner_0,
                      state.eyeradius_1_inner_1,
                      state.eyeradius_1_inner_2,
                      state.eyeradius_1_inner_3,
                    ],
                  },
                  {
                    outer: [
                      state.eyeradius_2_outer_0,
                      state.eyeradius_2_outer_1,
                      state.eyeradius_2_outer_2,
                      state.eyeradius_2_outer_3,
                    ],
                    inner: [
                      state.eyeradius_2_inner_0,
                      state.eyeradius_2_inner_1,
                      state.eyeradius_2_inner_2,
                      state.eyeradius_2_inner_3,
                    ],
                  },
                ]}
                eyeColor={[
                  {
                    outer: state.eyecolor_0_outer ?? state.qrColor ?? "#000000",
                    inner: state.eyecolor_0_inner ?? state.qrColor ?? "#000000",
                  },
                  {
                    outer: state.eyecolor_1_outer ?? state.qrColor ?? "#000000",
                    inner: state.eyecolor_1_inner ?? state.qrColor ?? "#000000",
                  },
                  {
                    outer: state.eyecolor_2_outer ?? state.qrColor ?? "#000000",
                    inner: state.eyecolor_2_inner ?? state.qrColor ?? "#000000",
                  },
                ]}
              />
            </div>
          </Col>
          {loading && <Loader />}
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <Button variant="primary" onClick={saveQRCode} className="px-5">
              Save
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Row className="justify-content-start">
          <Col xs="auto">
            <Button variant="primary" onClick={saveQRCode} className="px-5">
          Save
        </Button>
          </Col>
        </Row>
      </Modal.Footer> */}
    </Modal>
  );
};

export default QRCodeGenerator;
