import {
  faArrowUpRightFromSquare,
  faDownload,
  faFolderOpen,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { getUserDetails } from "../redux/Actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import Attendants from "./Attendants";
import axios from "axios";
import { message } from "antd";
import { BACKEND_URL } from "../config";

const Documents = () => {
  const [permissions, setPermissions] = useState({
    viewAttendants: false,
    viewValets: false,
    viewBellmans: false,
  });

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userDetails);

  // const handleDownload = async (fileUrl, fileName) => {
  //   if (!fileUrl) {
  //     alert("This image has not been added yet");
  //     return;
  //   } else {
  //     try {
  //       const response = await axios({
  //         url: fileUrl,
  //         method: "GET",
  //         responseType: "blob",
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       });

  //       const blob = new Blob([response.data], {
  //         type: response.headers["content-type"],
  //       });

  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", fileName);
  //       document.body.appendChild(link);
  //       link.click();

  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       alert("Error downloading the image");
  //       console.error("Error downloading the image:", error);
  //     }
  //   }
  // };
  const handleDownload = async (fileName) => {
    if (!fileName) {
      message.warning("File not found");
      return;
    }
    const downloadMessage = message.loading("Downloading", 0);
    try {
      const response = await axios({
        url: `${BACKEND_URL}api/upload/qrCode?fileName=${encodeURIComponent(
          fileName
        )}`,
        method: "GET",
        responseType: "blob",
      });
      // Extract the file name from the url
      const extractedFileName = fileName.split("/").pop();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", extractedFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success("Downloaded!");
    } catch (error) {
      message.error("Error downloading the file");
      console.error("Error downloading the file:", error);
    } finally {
      downloadMessage();
    }
  };

  const handleDownloadDocument = (fileId) => {
    if (!fileId) {
      message.warning("This documnet is unavailable");
      return;
    }
    const link = `https://drive.google.com/uc?export=download&id=${fileId}`;
    window.location.href = link;
  };
  const handleChatbotLink = (id, type, location) => {
    let link;
    if (location) {
      link = `https://dashboard.tipkit.co/bot?ref=${type}&hotel=${id}&location=${location}`;
    } else {
      link = `https://dashboard.tipkit.co/bot?ref=${type}&hotel=${id}`;
    }
    window.open(link, "_blank");
  };
  const handleOpenLink = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      if (user.isAdmin) {
        setPermissions({
          viewAttendants: user.services?.maids || false,
          viewValets: user.services?.valets || false,
          viewBellmans: user.services?.bellmen || false,
        });
      } else if (user.isAdmin === false) {
        setPermissions({
          viewAttendants: user.roles.permissions.viewAttendants || false,
          viewValets: user.roles.permissions.viewValets || false,
          viewBellmans: user.roles.permissions.viewBellmans || false,
        });
      }
    }
  }, [user]);

  return (
    <>
      {user ? (
        <Row className="m-0 p-4">
          <Col>
            <h4 className="text-center text-primary fw-bold">Chatbots</h4>
            <Row className="my-5">
              <Col>
                {permissions.viewAttendants && (
                  <Row className="justify-content-between align-items-center border border-1 p-2 rounded-3 my-3">
                    <Col xs={2} className="fw-bold text-primary">
                      For Attendants
                    </Col>
                    <Col
                      xs={7}
                      className="font-monospace bg-light rounded-4 py-1 text-muted"
                    >
                      {`https://dashboard.tipkit.co/bot?ref=maid&hotel=${user._id}`}
                    </Col>
                    <Col xs={3} className="ms-auto">
                      {user?.isAdmin == true && (
                        <Button
                          variant="outline-success"
                          className="border-0 rounded-4 mx-1"
                          onClick={() =>
                            handleDownload(
                              user.qrCodes.forMaids,
                              "attendants-QR-code"
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faQrcode}
                            className="fs-5 me-2"
                          />
                          Download
                        </Button>
                      )}
                      <Button
                        variant="outline-primary"
                        className="border-0 rounded-4 mx-1"
                        onClick={() => handleChatbotLink(user._id, "maid")}
                      >
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          className="fs-5 me-2"
                        />
                        Open
                      </Button>
                    </Col>
                  </Row>
                )}
                {permissions.viewValets && (
                  <Row className="justify-content-between align-items-center border border-1 p-2 rounded-3 my-3">
                    <Col xs={2} className="fw-bold text-primary">
                      For Valets
                    </Col>
                    <Col
                      xs={7}
                      className="font-monospace bg-light rounded-4 py-1 text-muted"
                    >
                      {`https://dashboard.tipkit.co/bot?ref=valet&hotel=${user._id}`}
                    </Col>
                    <Col xs={3} className="">
                      {user?.isAdmin == true && (
                        <Button
                          variant="outline-success"
                          className="border-0 rounded-4 mx-1"
                          onClick={() =>
                            handleDownload(
                              user.qrCodes.forValets,
                              "valets-QR-code"
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faQrcode}
                            className="fs-5 me-2"
                          />
                          Download
                        </Button>
                      )}
                      <Button
                        variant="outline-primary"
                        className="border-0 rounded-4 mx-1"
                        onClick={() => handleChatbotLink(user._id, "valet")}
                      >
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          className="fs-5 me-2"
                        />
                        Open
                      </Button>
                    </Col>
                  </Row>
                )}
                {permissions.viewBellmans && (
                  <Row className="justify-content-between align-items-center border border-1 p-2 rounded-3 my-3">
                    <Col xs={2} className="fw-bold text-primary">
                      For Bell Desk
                    </Col>
                    <Col
                      xs={7}
                      className="font-monospace bg-light rounded-4 py-1 text-muted"
                    >
                      {`https://dashboard.tipkit.co/bot?ref=bellman&hotel=${user._id}`}
                    </Col>
                    <Col xs={3}>
                      {user?.isAdmin == true && (
                        <Button
                          variant="outline-success"
                          className="border-0 rounded-4 mx-1"
                          onClick={() =>
                            handleDownload(
                              user.qrCodes.forBellmen,
                              "bellmen-QR-code"
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faQrcode}
                            className="fs-5 me-2"
                          />
                          Download
                        </Button>
                      )}
                      <Button
                        variant="outline-primary"
                        className="border-0 rounded-4 mx-1"
                        onClick={() => handleChatbotLink(user._id, "bellman")}
                      >
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          className="fs-5 me-2"
                        />
                        Open
                      </Button>
                    </Col>
                  </Row>
                )}
                {user?.services?.valets == true &&
                  user.valetLocations.length > 0 && (
                    <>
                      {user.valetLocations.map((location, index) => (
                        <Row className="justify-content-between align-items-center border border-1 p-2 rounded-3 my-3">
                          <Col xs={2} className="fw-bold text-primary">
                            {location.locationName}
                          </Col>
                          <Col
                            xs={7}
                            className="font-monospace bg-light rounded-4 py-1 text-muted text-wrap"
                          >
                            {`https://dashboard.tipkit.co/bot?ref=valet&hotel=${user._id}&location=${location.locationName}`}
                          </Col>
                          <Col xs={3}>
                            {user?.isAdmin == true && (
                              <Button
                                variant="outline-success"
                                className="border-0 rounded-4 mx-1"
                                onClick={() =>
                                  handleDownload(
                                    location.qrCode,
                                    location.locationName
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faQrcode}
                                  className="fs-5 me-2"
                                />
                                Download
                              </Button>
                            )}
                            <Button
                              variant="outline-primary"
                              className="border-0 rounded-4 mx-1"
                              onClick={() =>
                                handleChatbotLink(
                                  user._id,
                                  "valet",
                                  location.locationName
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="fs-5 me-2"
                              />
                              Open
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
              </Col>
            </Row>
            <h4 className="text-center text-primary fw-bold">
              Download helping documents
            </h4>
            <Row className="my-5">
              <Col>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          className="fs-5 text-primary"
                        />
                      </Col>
                      <Col md={6}>How to onboard your hotel</Col>
                      <Col xs="auto" className="ms-auto">
                        <Button
                          onClick={() => {
                            handleDownloadDocument(
                              "1kufslCghAXCtgcogSnE-lvGSXcetSu3A"
                            );
                          }}
                          variant="outline-success"
                          className="rounded-4 border-0 fs-5"
                        >
                          <FontAwesomeIcon icon={faDownload} className="" />
                        </Button>

                        <Button
                          variant="outline-primary"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleOpenLink(
                              "https://drive.google.com/file/d/1kufslCghAXCtgcogSnE-lvGSXcetSu3A/view?usp=sharing"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className=""
                          />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <hr className="m-0 p-0" />
                  <ListGroup.Item>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          className="fs-5 text-primary"
                        />
                      </Col>
                      <Col md={6}>How to onboard your team</Col>
                      <Col xs="auto" className="ms-auto">
                        <Button
                          variant="outline-success"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleDownloadDocument(
                              "1nSl617jXpGUNj8px8_HwDYnvWod6zhvd"
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} className="" />
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="rounded-4 border-0 fs-5"
                          onClick={() => {
                            handleOpenLink(
                              "https://drive.google.com/file/d/1nSl617jXpGUNj8px8_HwDYnvWod6zhvd/view?usp=sharing"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className=""
                          />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <hr className="m-0 p-0" />
                  {permissions.viewAttendants && (
                    <>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col xs="auto">
                            <FontAwesomeIcon
                              icon={faFolderOpen}
                              className="fs-5 text-primary"
                            />
                          </Col>
                          <Col md={6}>Starter guide for room attendants</Col>
                          <Col xs="auto" className="ms-auto">
                            <Button
                              variant="outline-success"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleDownloadDocument(
                                  "10Bs_NEAkYuqEK6XCCVTD-D6s4bD1UGkk"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="" />
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleOpenLink(
                                  "https://drive.google.com/file/d/10Bs_NEAkYuqEK6XCCVTD-D6s4bD1UGkk/view?usp=sharing"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className=""
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="m-0 p-0" />
                    </>
                  )}
                  {permissions.viewValets && (
                    <>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col xs="auto">
                            <FontAwesomeIcon
                              icon={faFolderOpen}
                              className="fs-5 text-primary"
                            />
                          </Col>
                          <Col md={6}>Starter guide for valets</Col>
                          <Col xs="auto" className="ms-auto">
                            <Button
                              variant="outline-success"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleDownloadDocument(
                                  "1KwxlfM-Yjq3d3G-hiKEup0z8AO42VrSC"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="" />
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleOpenLink(
                                  "https://drive.google.com/file/d/1KwxlfM-Yjq3d3G-hiKEup0z8AO42VrSC/view?usp=sharing"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className=""
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="m-0 p-0" />
                    </>
                  )}
                  {permissions.viewBellmans && (
                    <>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col xs="auto">
                            <FontAwesomeIcon
                              icon={faFolderOpen}
                              className="fs-5 text-primary"
                            />
                          </Col>
                          <Col md={6}>Starter guide for bell desk</Col>
                          <Col xs="auto" className="ms-auto">
                            <Button
                              variant="outline-success"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleDownloadDocument(
                                  "1zk71xkuhA8TklOHK9cLOc2Xl7KDaqCmC"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="" />
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-4 border-0 fs-5"
                              onClick={() => {
                                handleOpenLink(
                                  "https://drive.google.com/file/d/1zk71xkuhA8TklOHK9cLOc2Xl7KDaqCmC/view?usp=sharing"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className=""
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="m-0 p-0" />
                    </>
                  )}
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default Documents;
