import axios from "axios";
import { BACKEND_URL } from "../../config";
import {
  FAILED_PAYMENT_GET_ALL_FAIL,
  FAILED_PAYMENT_GET_ALL_REQUEST,
  FAILED_PAYMENT_GET_ALL_SUCCESS,
  GET_ALL_PAYMENT_REPORTS,
  GET_CLIENT_PAYMENTS_FAIL,
  GET_CLIENT_PAYMENTS_REQUEST,
  GET_CLIENT_PAYMENTS_SUCCESS,
  GET_PAYMENT_REPORTS_FAIL,
  GET_PAYMENT_REPORTS_REQUEST,
  GET_PAYMENT_REPORTS_SUCCESS,
  GET_TEAM_PAYOUTS_FAIL,
  GET_TEAM_PAYOUTS_REQUEST,
  GET_TEAM_PAYOUTS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  PAYMENT_GET_ALL_FAIL,
  PAYMENT_GET_ALL_REQUEST,
  PAYMENT_GET_ALL_SUCCESS,
  PAYMENTS_BY_DATE_FAIL,
  PAYMENTS_BY_DATE_REQUEST,
  PAYMENTS_BY_DATE_SUCCESS,
} from "../Constants/paymentConstants";

export const getPaymentReports = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_PAYMENT_REPORTS_REQUEST });
    const { data } = await axios.get(
      `${BACKEND_URL}payment/reports?page=${page}&limit=${limit}`
    );
    dispatch({ type: GET_PAYMENT_REPORTS_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: GET_PAYMENT_REPORTS_FAIL, payload: errorMessage });
  }
};
export const getAllPaymentReports = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PAYMENT_REPORTS_REQUEST });
    const { data } = await axios.get(`${BACKEND_URL}payment/reports?limit=all`);
    dispatch({ type: GET_ALL_PAYMENT_REPORTS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: GET_PAYMENT_REPORTS_FAIL, payload: errorMessage });
  }
};
export const getPaymentsByDate = (startDate, endDate) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENTS_BY_DATE_REQUEST });
    const queryParams = new URLSearchParams({
      start: startDate,
      end: endDate,
    }).toString();
    const { data } = await axios.get(
      `${BACKEND_URL}payment/reports/date?${queryParams}`
    );
    console.log("Payments by date in action :", data);
    dispatch({
      type: PAYMENTS_BY_DATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENTS_BY_DATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getTeamPayouts = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAM_PAYOUTS_REQUEST });
    const { data } = await axios.get(
      `${BACKEND_URL}payment/payout-reports?page=${page}&limit=${limit}`
    );
    dispatch({ type: GET_TEAM_PAYOUTS_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: GET_TEAM_PAYOUTS_FAIL, payload: errorMessage });
  }
};

export const getClientPayments =
  (clientId, page, limit) => async (dispatch) => {
    try {
      dispatch({ type: GET_CLIENT_PAYMENTS_REQUEST });
      const { data } = await axios.get(
        `${BACKEND_URL}payment/client?clientId=${clientId}&page=${page}&limit=${limit}`
      );
      dispatch({ type: GET_CLIENT_PAYMENTS_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: GET_CLIENT_PAYMENTS_FAIL, payload: errorMessage });
    }
  };

export const getAllFailedPayments = () => async (dispatch) => {
  try {
    dispatch({ type: FAILED_PAYMENT_GET_ALL_REQUEST });
    const { data } = await axios.get(`${BACKEND_URL}payment/failed`);
    dispatch({ type: FAILED_PAYMENT_GET_ALL_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: FAILED_PAYMENT_GET_ALL_FAIL, payload: errorMessage });
  }
};
