import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRegister as register } from "../redux/Actions/userAction";
import Message from "../components/Message";
import Loader from "../components/Loader";

const Register = ({ setUserListUpdate, setCreatedPassword, userId }) => {
  const [validated, setValidated] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [model, setMoadel] = useState({
    b2b: false,
    b2c: false,
    adminX: false,
  });
  const [isAdminX, setIsAdminX] = useState(false);
  const [name, setName] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [docLink, setDocLink] = useState("");
  const [accountStatus, setAccountStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [message, setMessage] = useState(null);
  let partnerId;
  let adminXId;

  const dispatch = useDispatch();
  setUserListUpdate(false);
  const { user } = useSelector((state) => state.userDetails);
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, success } = userRegister;

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (password !== confirmPassword && form.checkValidity() === true) {
      setMessage("Password do not match");
    } else if (password == confirmPassword && form.checkValidity() === true) {
      setMessage(null);
      if (user.isAdminX) {
        partnerId = user.partnerId;
        adminXId = userId;
      } else {
        partnerId = userId;
        adminXId = null;
      }
      console.log("Partner Id in register:", partnerId);
      dispatch(
        register(
          companyName,
          partnerId,
          roomNumber,
          email,
          phoneNumber,
          location,
          // password,
          isAdmin,
          name,
          googleLink,
          docLink,
          model,
          isAdminX,
          adminXId
        )
      );
      setUserListUpdate(true);
    }
  };
  return (
    <>
      <Row className="px-5 my-3">
        <Col>
          {error ? <Message>{error}</Message> : null}
          {message ? <Message variant="danger">{message}</Message> : null}
          {loading ? <Loader /> : null}
          {success ? (
            <Alert variant="success" className="text-center">
              Added successfully !
            </Alert>
          ) : (
            <Form noValidate validated={validated} onSubmit={submitHandler}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Contact Person Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      pattern="[A-Za-z ]+"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label>Number of Rooms</Form.Label>
                <Form.Control
                  required
                  type="number"
                  // pattern="[A-Za-z ]+"
                  value={roomNumber}
                  onChange={(e) => setRoomNumber(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Enter a valid Email*
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  pattern="^[0-9+ ]+$"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Enter a valid Phone Number*
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label>Client Model</Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      required
                      type="radio"
                      label="B2B"
                      checked={model.b2b}
                      onChange={() => setMoadel({ b2b: true, b2c: false })}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      label="B2C (1099 Model)"
                      checked={model.b2c}
                      onChange={() => setMoadel({ b2b: false, b2c: true })}
                    />
                  </Col>
                  {user.isAdminX && (
                    <Col>
                      <Form.Check
                        disabled={!user.chargesStatus}
                        type="radio"
                        label="Connected"
                        checked={model.adminX}
                        onChange={() =>
                          setMoadel({ b2b: false, b2c: false, adminX: true })
                        }
                      />
                      {!user.chargesStatus && (
                        <Form.Text
                          className="text-danger"
                          style={{ fontSize: "10px" }}
                        >
                          Stripe account not completed!
                        </Form.Text>
                      )}
                    </Col>
                  )}
                </Row>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label>PandaDoc Document Link</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={docLink}
                  onChange={(e) => {
                    setDocLink(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group> */}
              <Row className="mt-4 align-items-center">
                {user.isSuperAdmin === true && (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id="adminXCheckBox"
                      label="Make an Admin X"
                      checked={isAdminX}
                      onChange={(e) => setIsAdminX(e.target.checked)}
                    />
                  </Col>
                )}
                <Col sm="auto" className="ms-auto">
                  <Button type="submit" className="btn-success px-5">
                    Register
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Register;
