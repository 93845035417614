// export const BACKEND_URL = "http://localhost:8000/";
export const BACKEND_URL = "/";

// /payment?maid=acct_1OS1fZQc29QGiI83&amount=10&review=Test%20review
// https://tipmaid.io/bot?hotel=6581905bb4f337191a27f014

// For normal client (Attendants flow)
// http://localhost:3000/payment?maid=6601b1d0ed14acd440d46130&account=acct_1OyH9oHH1w3GBW2D&hotel=65fb129742c9e30253677583&amount=5&review=Good

// For b2b client (Attendants flow)
// http://localhost:3000/payment?maid=673cbfa604814cd7de128ff4&account=acct_1QMuOsQbFmhEOeyp&hotel=673cbe5c04814cd7de119fe2&amount=5&review=Good

// For normal client (Valet flow)
// http://localhost:3000/payment?maid=acct_1PIuwnQUIemRCuRN&hotel=65fb129742c9e30253677583&amount=5&review=Good

// For testing biz dev partners
// http://localhost:3000/payment?maid=acct_1OlxEYH4wk1diJVR&hotel=65d4dea0204b2f7895688bea&amount=5&review=Good

// For Valet team tips
// http://localhost:3000/teamtip?hotel=65fb129742c9e30253677583&location=test1
// http://localhost:3000/bot?ref=valet&hotel=65fb129742c9e30253677583&location=Test1

// For Valet team tips (b2b)
// http://localhost:3000/teamtip?hotel=673cbe5c04814cd7de119fe2&location=test1&sessionId=123
// http://localhost:3000/bot?ref=valet&hotel=673cbe5c04814cd7de119fe2&location=Test1

// For testing apple and google play (Live mode)

// https://tipmaid.io/payment/test?maid=acct_1OlwZuQWPNOrZyS4&hotel=65d4a1fc204b2f7895688602&amount=2&review=Test

// hotel: 65d4a1fc204b2f7895688602
// maid id : 65d4d928204b2f7895688bd2 (Ioana)
// maid stripe id : acct_1OlwZuQWPNOrZyS4
