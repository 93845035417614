import {
  FAILED_PAYMENT_GET_ALL_FAIL,
  FAILED_PAYMENT_GET_ALL_REQUEST,
  FAILED_PAYMENT_GET_ALL_SUCCESS,
  GET_ALL_PAYMENT_REPORTS,
  GET_CLIENT_PAYMENTS_FAIL,
  GET_CLIENT_PAYMENTS_REQUEST,
  GET_CLIENT_PAYMENTS_SUCCESS,
  GET_PAYMENT_REPORTS_FAIL,
  GET_PAYMENT_REPORTS_REQUEST,
  GET_PAYMENT_REPORTS_SUCCESS,
  GET_TEAM_PAYOUTS_FAIL,
  GET_TEAM_PAYOUTS_REQUEST,
  GET_TEAM_PAYOUTS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  PAYMENT_GET_ALL_FAIL,
  PAYMENT_GET_ALL_REQUEST,
  PAYMENT_GET_ALL_SUCCESS,
  PAYMENTS_BY_DATE_FAIL,
  PAYMENTS_BY_DATE_REQUEST,
  PAYMENTS_BY_DATE_SUCCESS,
} from "../Constants/paymentConstants";

export const getPaymentReportsReducer = (
  state = {
    loading: false,
    payments: [],
    allPayments: [],
    totalPayments: 0,
    totalPages: 0,
    currentPage: 1,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case GET_PAYMENT_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PAYMENT_REPORTS:
      return {
        ...state,
        loading: false,
        allPayments: action.payload.payments,
      };
    case GET_PAYMENT_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payments: action.payload.payments,
        totalPayments: action.payload.totalPayments,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case GET_PAYMENT_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const paymentsByDateReducer = (
  state = { payments: [], totalPayments: 0 },
  action
) => {
  switch (action.type) {
    case PAYMENTS_BY_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAYMENTS_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        payments: action.payload.payments,
        totalPayments: action.payload.totalPayments,
      };
    case PAYMENTS_BY_DATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const failedPaymentGetAllReducer = (
  state = { failedPayments: [] },
  action
) => {
  switch (action.type) {
    case FAILED_PAYMENT_GET_ALL_REQUEST:
      return { loading: true, failedPayments: [] };
    case FAILED_PAYMENT_GET_ALL_SUCCESS:
      return { loading: false, failedPayments: action.payload };
    case FAILED_PAYMENT_GET_ALL_FAIL:
      return { loading: false, error: action.payload, failedPayments: [] };
    default:
      return state;
  }
};

export const getTeamPayoutsReducer = (
  state = {
    loading: false,
    teamPayouts: [],
    totalTeamPayouts: 0,
    totalPages: 0,
    currentPage: 1,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case GET_TEAM_PAYOUTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_PAYOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamPayouts: action.payload.teamPayouts,
        totalTeamPayouts: action.payload.totalTeamPayouts,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case GET_TEAM_PAYOUTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getClientPaymentsReducer = (
  state = {
    loading: false,
    payments: [],
    totalPayments: 0,
    totalPages: 0,
    currentPage: 1,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CLIENT_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payments: action.payload.payments,
        totalPayments: action.payload.totalPayments,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case GET_CLIENT_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
