import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  faBars,
  faChartColumn,
  faChevronDown,
  faCircleUser,
  faCubes,
  faHandshakeAngle,
  faMoneyBillTransfer,
  faPowerOff,
  faArrowLeft,
  faChartPie,
  faHouseUser,
  faHandshake,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../images/tipkitLogo.svg";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  impersonateUserEnd,
  userLogout,
} from "../../redux/Actions/userAction";
import { setActiveTab } from "../../redux/Actions/tabAcions";
// import AdminDashboard from "./AdminDashboard";
import AdminProfilePage from "./../AdminProfilePage";
// import BizDev from "./BizDev";
// import TransactionReports from "./TransactionReports";
// import BizDevReports from "./BizDevRoperts";
// import Stats from "./Stats";
// import EditClientDetails from "./EditClientDetails";
// import OverlayLoader from "../components/OverlayLoader";
// import QrAnalytics from "./QrAnalytics";
import AdminXDashboard from "./AdminXDashboard";
import AttendantsList from "../AttendantsList";
import Attendants from "../Attendants";
import Valets from "../Valets";
import BellMan from "../Bellman";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import AdminXReports from "./AdminXReports";
// const TransactionReports = lazy(() => import("./TransactionReports"));

const AdminX = () => {
  const [permissions, setPermissions] = useState({
    isAdmin: false,
    canRead: false,
    toDownload: false,
    toAddMaid: false,
    toDeleteMaid: false,
    viewAttendants: false,
    viewValets: false,
    viewBellmans: false,
    adminServiceMaids: false,
    adminServiceValets: false,
    adminServiceBellmen: false,
  });
  const [props, setProps] = useState(null);
  const [showServices, setShowServices] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const isImpersonating = useSelector(
    (state) => state.userLogin.userInfo.userImpersonating
  );
  const activeTab = useSelector((state) => state.navigation.activeTab);
  const { user } = useSelector((state) => state.userDetails);
  const [screenLoading, setScreenLoading] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    window.location.href = "/login";
  };
  // const handleTabChange = (tabKey, propsValue = null) => {
  //   setProps(propsValue);
  //   dispatch(setActiveTab(tabKey));
  // };
  const handleTabChange = (tabKey, propsValue = null) => {
    if (activeTab !== tabKey) {
      setProps(propsValue);
      dispatch(setActiveTab(tabKey));
    }
  };

  const handleBackToSuperAdmin = () => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(!screenLoading);
      dispatch(
        impersonateUserEnd(isImpersonating.id, isImpersonating.email, navigate)
      );
    }, 1000);
  };

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    }
  }, [dispatch]);

  useEffect(() => {
    const updatedPermissions = {
      ...permissions,
      isAdmin: user.isAdmin,
      canRead: user?.roles?.permissions?.read || false,
      toDownload: user?.roles?.permissions?.download || false,
      toAddMaid: user?.roles?.permissions?.addMaid || false,
      toDeleteMaid: user?.roles?.permissions?.deleteMaid || false,
      viewAttendants: user.isAdmin
        ? user.services.maids
        : user.roles?.permissions?.viewAttendants || false,
      viewValets: user.isAdmin
        ? user?.services?.valets
        : user?.roles?.permissions?.viewValets || false,
      viewBellmans: user.isAdmin
        ? user?.services?.bellmen
        : user?.roles?.permissions?.viewBellmans || false,
    };
    setPermissions((prevPermissions) => {
      return JSON.stringify(prevPermissions) !==
        JSON.stringify(updatedPermissions)
        ? updatedPermissions
        : prevPermissions;
    });
    // setPermissions(updatedPermissions);
  }, [user]);
  return (
    <>
      {/* {screenLoading ? <OverlayLoader /> : null} */}
      {isImpersonating && (
        <div className="py-1 bg-success">
          <Container
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            {/* "Logged in as" label */}
            <span className="text-light fs-7">
              <strong> {isImpersonating.name}</strong> Logged in as: {user.name}
              <strong>
                {user && (user.isAdmin ? user.companyName : user.name)}
              </strong>
            </span>
            {/* "Back to Super Admin" button */}
            <Button
              variant="outline-success text-light border-0"
              size="sm"
              onClick={handleBackToSuperAdmin}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2 text-light" />
              Back to {isImpersonating.name || "dashboard"}
            </Button>
          </Container>
        </div>
      )}
      <Navbar bg="primary" variant="dark" className="py-3">
        <Container fluid className="p-0">
          <Row className="justify-content-between align-items-center w-100">
            <Col xs="auto" className={`text-start px-4`}>
              <Button className="fs-5" onClick={() => setCollapsed(!collapsed)}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
            </Col>
            <Col xs="auto" className="text-center">
              <Navbar.Brand>
                <img
                  src={Logo}
                  alt="tipkit"
                  width="100"
                  className="d-inline-block align-top"
                />
              </Navbar.Brand>
            </Col>
            <Col xs="auto">
              <Dropdown align={"end"}>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="custom-dropdown-toggle"
                >
                  <FontAwesomeIcon icon={faChevronDown} className="fs-5" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="px-2">
                  <Button
                    className="w-100 border-0"
                    variant="outline-danger"
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon icon={faPowerOff} className="me-2" />{" "}
                    Logout
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </Navbar>

      <Tab.Container activeKey={activeTab}>
        <Row className="m-0">
          <Col
            sm={collapsed ? 1 : 2}
            className={`sidebar vh-100 bg-primary sticky-top ${
              collapsed ? "collapsed" : "expanded"
            }`}
          >
            <Row className="h-100">
              <div className="pt-5 mt-5 w-100">
                <Button
                  variant={activeTab === "#dashboard" ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => handleTabChange("#dashboard")}
                >
                  <FontAwesomeIcon icon={faHandshake} className="me-3" />
                  {!collapsed && "Partners"}
                </Button>
                <Button
                  variant={showServices ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => setShowServices(!showServices)}
                >
                  <FontAwesomeIcon
                    className="me-3"
                    icon={showServices ? faChevronUp : faChevronDown}
                  />
                  {!collapsed && "Services"}
                </Button>
                {!collapsed && (
                  <Row>
                    <Col
                      className={`transition ps-5 ${
                        showServices ? "visible" : "hidden"
                      }`}
                    >
                      {permissions.viewAttendants && (
                        <div
                          className={`button-wrapper ${
                            activeTab === "#attendant" ? "active-tab" : ""
                          }`}
                        >
                          <div className="indicator" />
                          <Button
                            variant={
                              activeTab === "#attendant"
                                ? "outline-success"
                                : "primary"
                            }
                            className="w-100 text-start border-0 fs-7"
                            onClick={() => handleTabChange("#attendant")}
                          >
                            Attendants
                          </Button>
                        </div>
                      )}
                      {permissions.viewValets && (
                        <div
                          className={`button-wrapper ${
                            activeTab === "#valet" ? "active-tab" : ""
                          }`}
                        >
                          <div className="indicator" />
                          <Button
                            variant={
                              activeTab === "#valet"
                                ? "outline-success"
                                : "primary"
                            }
                            className="w-100 text-start border-0 fs-7"
                            onClick={() => handleTabChange("#valet")}
                          >
                            Valets
                          </Button>
                        </div>
                      )}
                      {permissions.viewBellmans && (
                        <div
                          className={`button-wrapper ${
                            activeTab === "#bellman" ? "active-tab" : ""
                          }`}
                        >
                          <div className="indicator" />
                          <Button
                            variant={
                              activeTab === "#bellman"
                                ? "outline-success"
                                : "primary"
                            }
                            className="w-100 text-start border-0 fs-7"
                            onClick={() => handleTabChange("#bellman")}
                          >
                            Bell Desk
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
                <Button
                  variant={activeTab === "#transactions" ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => {
                    handleTabChange("#transactions");
                    // setCollapsed(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBillTransfer}
                    className="me-3"
                  />
                  {!collapsed && "Reports"}
                </Button>
                {/* <Button
                  variant={activeTab === "#stats" ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => handleTabChange("#stats")}
                >
                  <FontAwesomeIcon icon={faChartColumn} className="me-3" />
                  {!collapsed && "Stats"}
                </Button> */}
                <Button
                  variant={activeTab === "#profilePage" ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => handleTabChange("#profilePage")}
                >
                  <FontAwesomeIcon icon={faCircleUser} className="me-3" />{" "}
                  {!collapsed && "Profile"}
                </Button>
              </div>
            </Row>
          </Col>
          <Col sm={collapsed ? 11 : 10} className="p-0 mx-auto">
            <Tab.Content>
              <Tab.Pane eventKey="#attendant">
                <Attendants />
              </Tab.Pane>
              <Tab.Pane eventKey="#valet">
                <Valets />
              </Tab.Pane>
              <Tab.Pane eventKey="#bellman">
                <BellMan />
              </Tab.Pane>

              <Tab.Pane eventKey="#dashboard">
                <AdminXDashboard onTabChange={handleTabChange} />
              </Tab.Pane>
              <Tab.Pane eventKey="#profilePage">
                <AdminProfilePage />
              </Tab.Pane>
              <Tab.Pane eventKey="#transactions">
                <AdminXReports />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="#stats">
                <Stats />
              </Tab.Pane> */}
              {/* <Tab.Pane eventKey="#qrStats">
                <QrAnalytics />
              </Tab.Pane> */}

              {/* <Tab.Pane eventKey="#editClient">
                <EditClientDetails clientId={props} />
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default AdminX;
